<template>
  <v-app>
    <v-app-bar app dense flat
               :dark="!isDark"
               :color="isDark ? 'white':'transparent'"
               v-if="officialWebsite.isMainStation"
               style="z-index: 12">
      <div class="h5-app-bar">
        <v-img
            @click="$router.push('/wmyH5/wmyH5Home')"
            max-width="200"
            height="30"
            alt="logo"
            contain
            position="left center"
            :src="isDark ? logoLan:logoBai"
        />
        <v-img @click.stop="drawer = !drawer" max-width="16" contain
               :src="drawer ? menuIconClose : (isDark ? menuIconHei : menuIconBai)"></v-img>
      </div>
      <div v-show="drawer" @click.stop
           class="app-bar-menu">
        <v-list light>
          <v-list-item to="/wmyH5/wmyH5Home" @click.stop="drawer = !drawer">
            <v-list-item-title class="menu-title">首页</v-list-item-title>
          </v-list-item>

          <v-list-group v-model="menuGroup">
            <template v-slot:appendIcon>
              <span :class="menuGroup ? 'rotate-180':'rotate-0'">
                <v-img max-width="15" contain :src="menuArrowB"></v-img>
              </span>
            </template>
            <template v-slot:activator>
              <v-list-item-title class="menu-title">产品中心</v-list-item-title>
            </template>

            <v-list-item
                class="ml-12"
                v-for="(info, i) in products"
                :key="i"
                :to="info.router"
                @click.stop="drawer = !drawer"
            >
              <v-list-item-title v-text="info.title" class="font-size-14 color-333"></v-list-item-title>
            </v-list-item>
          </v-list-group>
          <!--<v-list-item to="/wmyH5/wmyH5Case" @click.stop="drawer = !drawer">-->
          <!--  <v-list-item-title class="menu-title">合作案例</v-list-item-title>-->
          <!--</v-list-item>-->
          <!--<v-list-item to="/wmyH5/agent" @click.stop="drawer = !drawer">-->
          <!--  <v-list-item-title class="menu-title">招商加盟</v-list-item-title>-->
          <!--</v-list-item>-->
          <!--<v-list-item to="/wmyH5/wmyH5About" @click.stop="drawer = !drawer">-->
          <!--  <v-list-item-title class="menu-title">关于我们</v-list-item-title>-->
          <!--</v-list-item>-->
        </v-list>
      </div>
    </v-app-bar>
    <v-main class="py-0">
      <v-dialog content-class="dis" v-model="drawer" style="z-index: 10"></v-dialog>
      <router-view @handleSuspend="handleSuspend"></router-view>
    </v-main>
    <transition name="fade" appear>
      <div class="hover-btn"
           v-if="officialWebsite.isMainStation"
           v-show="officialWebsite.showReturnTip"
           @click.stop="$vuetify.goTo(0, {})">
        <v-img src="../../../../public/img/icons/menu/returnTop.png"></v-img>
      </div>
    </transition>
    <v-dialog v-model="dialog" content-class="susDialog" :transition="false">
      <div class="suspend-dialog">
        <div class="content-phone" @click.stop v-show="currentHover === 'phone'">
          <v-card color="transparent" class="position-r" flat>
            <div class="close-btn" @click.stop="handleCloseDialog">
              <v-img max-width="12" contain src="../../../../public/img/icons/menu/h5_close_btn.png"></v-img>
            </div>
            <v-row class="pa-0 justify-center mt-4">
              <v-card-title class="pa-0 justify-center font-size-18 line-height-25 font-weight-bold">联系我们</v-card-title>
            </v-row>
            <v-row class="pa-0 mt-5 ml-6">
              <div class="d-inline width-40">
                <v-avatar size="40" rounded-2>
                  <v-img contain src="../../../../public/img/icons/menu/cooperation.png"></v-img>
                </v-avatar>
              </div>
              <div class="d-inline ml-3 mt-n1">
                <v-card color="transparent" flat>
                  <v-card-title class="pa-0 font-size-16 font-weight-bold line-height-22 mt-2">
                    商户合作
                  </v-card-title>
                  <v-card-text class="pa-0 font-size-14 line-height-20 mt-1">yizhe.han@ophyer.com</v-card-text>
                </v-card>
              </div>
            </v-row>
            <v-row class="pa-0 mt-7 ml-6">
              <div class="d-inline width-40">
                <v-avatar size="40" rounded-2>
                  <v-img width="40" height="40" contain src="../../../../public/img/icons/menu/consult.png"></v-img>
                </v-avatar>
              </div>
              <div class="d-inline ml-3 mt-n1">
                <v-card color="transparent" flat>
                  <v-card-title class="pa-0 font-size-16 font-weight-bold line-height-22  mt-2">
                    SaaS 产品咨询
                  </v-card-title>
                  <v-card-text class="pa-0 mt-1 font-size-14 line-height-20">010-63037996</v-card-text>
                </v-card>
              </div>
            </v-row>
            <v-row class="pa-0 mt-7 ml-6">
              <div class="d-inline width-40">
                <v-avatar size="40" rounded-2>
                  <v-img width="40" height="40" contain src="../../../../public/img/icons/menu/joinIn.png"></v-img>
                </v-avatar>
              </div>
              <div class="d-inline ml-3 mt-n1">
                <v-card color="transparent" flat>
                  <v-card-title class="pa-0 font-size-16 font-weight-bold line-height-22  mt-2">
                    招商加盟
                  </v-card-title>
                  <v-card-text class="pa-0 mt-1 font-size-14 line-height-20">13916184657</v-card-text>
                </v-card>
              </div>
            </v-row>
          </v-card>
        </div>
        <div class="content-follow" @click.stop v-show="currentHover === 'follow'">
          <div class="close-btn" @click.stop="handleCloseDialog">
            <v-img max-width="12" contain src="../../../../public/img/icons/menu/h5_close_btn.png"></v-img>
          </div>
          <v-card color="transparent" flat>
            <v-row class="pa-0 mt-5 justify-center">
              <v-card-title class="pa-0 justify-center font-size-18 line-height-25 font-weight-bold">关注我们</v-card-title>
            </v-row>
            <v-row class="pa-0 justify-center mt-1">
              <v-img src="../../../../public/img/icons/menu/qrCode.png" max-width="236" contain></v-img>
              <v-card-title class="font-size-14 pa-0 line-height-20 color-666">关注飞天云动公众号</v-card-title>
            </v-row>
          </v-card>
        </div>
        <div class="content-tryout" @click.stop v-show="currentHover === 'tryout'">
          <v-card color="transparent" flat width="100%" class="position-r">
            <div class="close-btn" @click.stop="handleCloseDialog">
              <v-img max-width="12" contain src="../../../../public/img/icons/menu/h5_close_btn.png"></v-img>
            </div>
            <v-card-title class="justify-center text-align-center font-size-18 pa-0 line-height-25 font-weight-bold">完善信息<br/>专属顾问马上联系您</v-card-title>
            <v-card-text class="text-align-center font-size-12 line-height-17 pa-0 mt-2">苏经理：18511864107</v-card-text>
            <v-form class="pa-0 mt-3">
              <v-container class="pa-0">
                <v-row class="pa-0">
                  <div class="font-size-15 font-weight-bold line-height-21">
                    姓名
                    <span style="color: red;vertical-align: sub;">*</span>
                  </div>
                  <v-text-field class="width-per-100" placeholder="请输入您的姓名" clearable  :rules="rules.name" v-model="form.name"></v-text-field>
                </v-row>
                <v-row class="pa-0" style="margin-top: 10px;">
                  <div class="font-size-15 font-weight-bold line-height-21">
                    电话
                    <span style="color: red;vertical-align: sub;">*</span>
                  </div>
                  <v-text-field class="width-per-100" placeholder="请输入您的电话" clearable :rules="rules.phone" v-model="form.phone"></v-text-field>
                </v-row>
                <v-row class="pa-0" style="margin-top: 10px;">
                  <div class="font-size-15 font-weight-bold line-height-21">
                    公司名称
                    <span style="color: red;vertical-align: sub;">*</span>
                  </div>
                  <v-text-field class="width-per-100" placeholder="请输入您的公司名称" clearable persistent-hint :rules="rules.conpanyName" v-model="form.conpanyName"></v-text-field>
                </v-row>
                <v-row class="pa-0" style="margin-top: 10px;">
                  <div class="font-size-15 font-weight-bold line-height-21">地区</div>
                  <v-text-field class="width-per-100" placeholder="请输入您所在的地区" persistent-hint v-model="form.address"></v-text-field>
                </v-row>
                <v-row class="pa-0 justify-center mt-5">
                  <div class="tryout-btn" @click.stop="handleSaveTryout">提交</div>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </div>
        <div class="content-agent" @click.stop v-show="currentHover === 'agent'">
          <v-card color="transparent" class="position-r" flat>
            <div class="close-btn" @click.stop="handleCloseDialog">
              <v-img max-width="12" contain src="../../../../public/img/icons/menu/h5_close_btn.png"></v-img>
            </div>
            <v-row class="pa-0 justify-center mt-5">
              <v-card-title class="pa-0 justify-center font-size-18 line-height-25 font-weight-bold">我要代理</v-card-title>
            </v-row>
            <div class="pa-0 mt-6 justify-center" style="text-align: center;">
              <div class="justify-center">
                <v-avatar size="40" rounded-2>
                  <v-img contain src="../../../../public/img/icons/menu/agent-icon.png"></v-img>
                </v-avatar>
              </div>
              <div class="ml-3 mt-2">
                <v-card color="transparent" flat>
                  <v-card-title class="pa-0 font-size-15 font-weight-bold line-height-20" style="margin-left:122px">
                    汪总
                  </v-card-title>
                  <v-card-text class="pa-0 font-size-17 line-height-20 mt-2">
                    <a href="tel:139-1618-4657" style="color: #2573E2;text-decoration: none">18601279119</a>
                  </v-card-text>
                </v-card>
              </div>
              <div class="justify-center mt-5">
                <v-img src="@/assets/login/wangWexin.png" width="200" height="200" contin style="margin: 0 auto;"></v-img>
                <v-card-text class="text-align-center font-size-14 pa-0 mt-3 mb-12 color-666">扫一扫加我微信</v-card-text>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </v-dialog>
    <v-footer
        v-if="officialWebsite.isMainStation"
      color="#272b2e"
      width="100%"
      min-width="330"
      class="justify-center text-align-center font-size-12"
      style="color: rgba(174, 184, 200, 0.8);line-height: 17px;padding: 10px 5px;margin-bottom: 56px;"
    >
      <!--© COPYRIGHT 2021 . ALL RIGHTS RESERVED. 北京飞天云动科技股份有限公司版权所有 京公网安备11010502037637号-->
      © COPYRIGHT 2021 . ALL RIGHTS RESERVED. 京公网安备11010502037637号
      <br/>京ICP备09083236号
    </v-footer>

    <v-bottom-navigation :value="bottomMenuVal" color="757575" app grow
                         v-if="officialWebsite.isMainStation">
      <v-btn @click.stop="handleSuspend('follow')">
        <div class="mt-1">关注我们</div>
        <v-img max-width="40" max-height="40" contain src="../../../../public/img/icons/menu/h5-btn01.png"></v-img>
      </v-btn>
      <v-btn @click.stop="handleSuspend('phone')">
        <div class="mt-1">联系我们</div>
        <v-img max-width="40" max-height="40" contain src="../../../../public/img/icons/menu/h5-btn02.png"></v-img>
      </v-btn>
      <v-btn @click.stop="handleSuspend('tryout')">
        <div class="mt-1">申请试用</div>
        <v-img max-width="40" max-height="40" contain src="../../../../public/img/icons/menu/h5-btn03.png"></v-img>
      </v-btn>
      <v-btn @click.stop="handleSuspend('agent')">
        <div class="mt-1">我要代理</div>
        <v-img max-width="40" max-height="40" contain src="../../../../public/img/icons/menu/h5-btn04.png"></v-img>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: 'H5',
  data: () => ({
    bottomMenuVal: 1,
    dialog: false,
    currentHover: "",
    form: {
      name: "",
      phone: "",
      conpanyName: "",
      address: ""
    },
    rules: {
      name: [
        value => !!value || '请输入姓名!',
      ],
      phone: [
        value => !!value || '请输入电话!',
      ],
      conpanyName: [
        value => !!value || '请输入公司名称!',
      ]
    },
    drawer: false,
    products: [
      {
        // title: "AR 创意营销",
        title: "AR 创意互动",
        router: "/wmyH5/wmyH5solutionAR"
      },
      {
        title: "VR 全景网站",
        router: "/wmyH5/wmyH5VRpanoramaWebsite"
      },
      {
        // title: "大客户定制营销",
        title: "大客户定制",
        router: "/wmyH5/wmyH5BigCustomer"
      },
    ],
    logoBai: require("@/assets/login/wanmuyun-logo-bai.png"),
    logoLan: require("@/assets/login/wanmuyun-logo.png"),
    menuIconClose: require("../../../../public/img/icons/menu/closeMenuH5.png"),
    menuIconHei: require("../../../../public/img/icons/menu/menuHei.png"),
    menuIconBai: require("../../../../public/img/icons/menu/menuBai.png"),
    menuArrowB: require("../../../../public/img/icons/menu/arrowB.png"),
    menuGroup: true
  }),
  watch: {
    $route(to) {
      const menuInfo = to.path.split("/");
      this.currentMenu = "/" + menuInfo[3];
    }
  },
  computed: {
    ...mapState(["officialWebsite"]),
    isDark() {
      if(this.officialWebsite.showReturnTip
          || this.drawer
          || this.$route.path === "/h5/CaseDetail"
          || this.$route.path === "/wmyH5/wmyH5CaseDetail"){
        return true;
      }
      return false;
    }
    // footerHeight () {
    //   switch (this.$vuetify.breakpoint.name){
    //     case 'xs': return 90
    //     case 'sm': return 65
    //     case 'md': return 50
    //     case 'lg': return 50
    //     case 'xl': return 50
    //     default: return 70
    //   }
    // }
  },
  mounted() {
    let _this = this;
    document.addEventListener("click", () => {
      _this.drawer = false;
      _this.dialog = false;
      _this.currentHover = "";
    });
  },
  methods: {
    handleSuspend: function (type){
      this.currentHover = type;
      this.dialog = true;
    },
    handleCloseDialog: function (){
      this.currentHover = "";
      this.dialog = false;
    },
    handleSaveTryout: function (){
      if(!this.form.name || !this.form.phone || !this.form.conpanyName){
        this.$lavMessage.warning("请填写必填项！");
        return;
      }
      this.$lavMessage.success("提交成功！");
      this.currentHover = "";
    },
  }
};
</script>

<style lang="scss" scoped>
//.h5_logo_btn{
//  position: fixed;
//  top: 0;
//  left: 10px;
//  z-index: 12;
//}
.h5-app-bar{
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  .bar-icon::after{
    display: none;
  }
}
.app-bar-menu{
  position: fixed;
  top: 48px;
  left: 0;
  width: 100%;
  .menu-title{
    font-weight: bold;
    font-size: 16px;
    color: #333333;
  }
}
::v-deep .susDialog{
  overflow-y: unset;
}
.suspend-dialog{
  display: flex;
  justify-content: center;
  .close-btn{
    width: 30px;
    height: 30px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 15px !important;
    border: 1px solid #FFFFFF;
    cursor: pointer;
    position: absolute;
    left: calc(50% - 15px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-phone{
    width: 300px;
    height: 290px;
    background-color: #FFFFFF;
    border-radius: 10px;
    position: relative;
    .close-btn{
      top: 300px;
    }
  }
  .content-agent{
    width: 300px;
    // height: 125px;
    background-color: #FFFFFF;
    border-radius: 10px;
    position: relative;
    .close-btn{
      top: 403px;
    }
  }
  .content-follow{
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding-bottom: 18px;
    position: relative;
    img {
      width: 180px;
      height: 180px;
      object-fit: contain;
    };
    .close-btn{
      top: 340px;
    }
  }
  .content-tryout{
    max-width: 500px;
    min-width: 300px;
    width: calc(100vw - 40px);
    display: flex;
    justify-content: center;
    border-radius: 10px;
    padding: 20px 30px 20px 30px;
    position: fixed;
    top: 47% !important;
    //left: 50%;
    z-index: 999;
    transform: translate(0,-50%);
    background-color: #FFFFFF;
    .tryout-btn {
      width: 100%;
      height: 40px;
      background: #2D87FF;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      letter-spacing:8px;
      cursor: pointer;
    }
    .close-btn{
      width: 30px;
      height: 30px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 15px !important;
      border: 1px solid #FFFFFF;
      cursor: pointer;
      position: absolute;
      top: 530px;
      left: calc(50% - 15px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  ::v-deep .v-dialog{
    box-shadow: unset;
  }
}
.hover-btn{
  width: 35px;
  height: 35px;
  border-radius: 20px;
  position: fixed;
  bottom: calc(20% - 55px);
  right: 5px;
  z-index:5;
  box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
  cursor: pointer;
}
</style>
